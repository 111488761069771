import React, { useEffect, useState } from "react";
import Logo from "../../img/about-section logos/ajivalogo_45.png";
import { headers } from "../../helpers/variables";
import needhelp from "../../img/Need-help.webp";
import lets from "../../img/Lats-talk.webp";
import pdf from "../../img/Pdf.webp";
import Pdffile from "../../img/about-section logos/AjivaInfotech.pdf";
import { NavLink, BrowserRouter } from "react-router-dom";
import {
  get_api_request,
  post_api_request,
  put_api_request,
  delete_api_request,
  api_url,
} from "../../helpers/Api.js";
const Header = () => {
  const [ActiveHome, setActiveHome] = useState(false);
  const [ActiveCaseStudies, setActiveCaseStudies] = useState(false);
  const [ActiveAbout, setActiveAbout] = useState(false);
  const [ActiveCareer, setActiveCareer] = useState(false);
  const [ActiveBlog, setActiveBlog] = useState(false);
  const [ActiveContact, setActiveContact] = useState(false);
  const [ActiveServicees, setActiveServicees] = useState(false);
  const [ActivePortfolio, setActivePortfolio] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [ishemBurger, sethemBurger] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [tools, settools] = useState(false);

  // const handleFormSubmit = (e) => {
  //   setShowThanksPopup(true);
  // };

  const navToggle = (event) => {
    setIsActive((current) => !current);
  };

  const hemBurger = (event) => {
    sethemBurger((current) => !current);
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const popupvalue = window.localStorage.getItem("Popup");
    if (popupvalue != "Disabled") {
      const delayedAction = () => {
        setOpen(true);
      };
      const timeoutId = setTimeout(delayedAction, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  function submit_form(form_id) {
    let e = document.getElementById(form_id),
      l = new FormData(e),
      t = Array.from(l.entries());
    var n = {};
    for (let o = 0; o < t.length; o++) {
      var s = t[o],
        i = s[0],
        y = s[1];
      y.length > 0 && (void 0 != n[i] ? (n[i] = n[i] + "," + y) : (n[i] = y));
    }
    return n;
  }
  const handleformsubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById("contactid");
    if (form.checkValidity()) {
      var feildsvalue = submit_form("contactid");
      console.log(feildsvalue);
      var payload = {};
      payload["fullname"] = feildsvalue?.fullname;
      payload["email"] = feildsvalue?.email;
      payload["phone"] = feildsvalue?.phone;
      payload["message"] = feildsvalue?.message;
    }
    async function Createcontact(payload) {
      const url = "/api/v1/contactus/";
      // console.log(url,"hhhhhhhhhhhhhhhhh")
      const response = await post_api_request(url, payload, headers);
      if (response.status == 201) {
        setShowThanksPopup(true);
      }
      if (response.status == 201) {
      } else {
      }
      {
        document.getElementById("contactid").reset();
      }
    }
    Createcontact(payload);
  };
  const handlemodal = () => {
    window.localStorage.setItem("Popup", "Disabled");
  };
  const handleMenuClick = (event) => {
    // Select all NavLinks
    const navLinks = document.querySelectorAll("nav ul li a");

    // Log the clicked element to the console
    console.log("Clicked element:", event.target);

    // Remove active class from all links
    navLinks.forEach((link) => {
      // Check if it contains the active class and remove it
      link.classList.remove("active");
    });

    // Add active class to the clicked link
    event.target.classList.add("active");
  };
  return (
    <>
      <div className="header_top header-section  bg-[#333333]">
        <div className="container m-auto">
          <div className="w-full flex justify-between">
            <div className="flex lg:pl-10 ">
              <a
                className="text-white py-2  flex items-center text-sm inner-section font-semibold"
                href="mailto:info@ajivainfotech.com"
              >
                info@ajivainfotech.com
              </a>
              <span className="phone_content p-">
                <i className="fa-solid fa-phone-volume text-white text-xl"></i>
              </span>
              <p className="text-white flex  py-2 text-sm uppercase font-semibold">
                <a className="flex items-center " href="tel:+91-86999-81982">
                  0172-4084189, +91-86999-81982
                </a>
              </p>
            </div>
            <div className="social_icon py-2 lg:ml-12">
              {/* <BrowserRouter> */}
              <NavLink
                target="_blank"
                to="https://www.facebook.com/Ajivainfotech/"
                aria-label="facebook login"
              >
                <i className="fa-brands fa-facebook-f text-white text-xl mx-2"></i>
              </NavLink>
              <NavLink
                target="_blank"
                to="https://www.instagram.com/ajivainfotech/"
                aria-label="instagram login"
              >
                <i className="fa-brands fa-instagram text-white text-xl mx-2"></i>
              </NavLink>
              <NavLink
                target="_blank"
                to="https://in.linkedin.com/company/ajivainfotech"
                aria-label="NavLinkedin login"
              >
                <i className="fa-brands fa-linkedin text-white text-xl mx-2"></i>
              </NavLink>
              <NavLink
                to="https://twitter.com/ajivainfotech"
                target="_blank"
                aria-label="NavLinkedin login"
              >
                <i className="fa-brands fa-x-twitter text-white text-xl mx-2"></i>
              </NavLink>
              {/* </BrowserRouter> */}
            </div>
          </div>
        </div>
      </div>
      <header className="header-section bg-white  py-2 border shadow sticky top-0 z-50 header_ajiva	">
        <div className="container m-auto">
          <div className="lg:px-0">
            <div className="flex lg:h-16 items-center justify-between  navbaar-responcive">
              <div className="md:flex md:items-center md:gap-12">
                <a className="block  text-teal-600" href="/">
                  <span className="sr-only">Home</span>
                  <div className=" logo-image-set mx-4 lg:mx-0">
                    <img
                      width="160"
                      height="70"
                      className=""
                      loading="lazy"
                      src={Logo}
                      alt="ajivainfotech logo images"
                    />
                    {/* <img
                      width=""https://ajivainfotech.com/api/v1/contactus/
                      height=""
                      className=""
                      loading="lazy"
                      src={Logo_mob}
                      alt="ajivainfotech logo images lg:hidden block"
                    /> */}
                  </div>
                </a>
              </div>
              <div
                className={
                  isActive
                    ? "hideRemove nav_v slide-in-left "
                    : "nav_v slide-in-right"
                }
              >
                {/* <BrowserRouter> */}
                <nav aria-label="Global">
                  <ul className=" navbaarresponcive lg:flex lg:items-center gap-6 text-sm lg:p-0  lg:text-center">
                    <li>
                      <NavLink
                        to="/"
                        className={
                          "lg:text-[#343434]  lg:text-base hover:text-red-600 lg:font-bold uppercase cursor-pointer"
                        }
                        onClick={(e) => handleMenuClick(e)}
                      >
                        Home
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/about"
                        className={
                          "lg:text-[#343434]  lg:text-base hover:text-red-600 lg:font-bold uppercase"
                        }
                        onClick={(e) => handleMenuClick(e)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/services"
                        className={
                          "lg:text-[#343434]  lg:text-base hover:text-red-600 lg:font-bold uppercase"
                        }
                        onClick={(e) => handleMenuClick(e)}
                      >
                        Services
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/portfolio"
                        className={
                          "lg:text-[#343434]  lg:text-base hover:text-red-600 lg:font-bold uppercase"
                        }
                        onClick={(e) => handleMenuClick(e)}
                      >
                        Portfolio
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/blog"
                        className={
                          "lg:text-[#343434]  lg:text-base hover:text-red-600 lg:font-bold uppercase"
                        }
                        onClick={(e) => handleMenuClick(e)}
                      >
                        Blog
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/career"
                        className={
                          "lg:text-[#343434]  lg:text-base hover:text-red-600 lg:font-bold uppercase"
                        }
                        onClick={(e) => handleMenuClick(e)}
                      >
                        Career
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/contact"
                        className={
                          "lg:text-[#343434]  lg:text-base hover:text-red-600 lg:font-bold uppercase"
                        }
                        onClick={(e) => handleMenuClick(e)}
                      >
                        Contact
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/tools"
                        className={
                          "text-[#343434]  text-base hover:text-red-600 font-bold uppercase"
                        }
                        onClick={(e) => handleMenuClick(e)}
                      >
                        Tools
                      </NavLink>
                    </li>
                    {/* <li>
                                     <NavLink
                                       to="/case_studies"
                                       className={
                                         ActiveCaseStudies
                                           ? "active font-bold uppercase text-base text-red-600"
                                           : "text-[#343434]  text-base hover:text-red-600 font-bold uppercase"
                                       }
                                       onClick={() => {
                                         setActiveHome(false);
                                         setActiveAbout(false);
                                         setActiveBlog(false);
                                         setActiveContact(false);
                                         setActiveServicees(false);
                                         setActivePortfolio(false);
                                         setActiveCaseStudies(true);
                                         window.location.href = "/case_studies";
                                       }}
                                     >
                                       CaseStudies
                                     </NavLink>
                                   </li> */}
                  </ul>
                </nav>
                {/* </BrowserRouter> */}
              </div>
              <div className="flex items-center gap-4 hidden md:block">
                <div className="sm:flex sm:gap-4 cursor-pointer">
                  <div
                    onClick={() => setOpen(true)}
                    className="group relative inline-block overflow-hidden border border-gray-600 px-8 py-3 focus:outline-none focus:ring  aj_btn_get_start"
                  >
                    <span className="absolute inset-y-0 left-0 w-[0px] bg-red-600 transition-all group-hover:w-full group-active:bg-red-600"></span>

                    <p className="relative text-sm font-medium text-[#181818] transition-colors group-hover:text-white text-base">
                      Let's Connect
                    </p>
                  </div>
                  <span className="absolute inset-y-0 left-0 w-[2px] bg-red-600 transition-all group-hover:w-full group-active:bg-red-600"></span>
                </div>
              </div>
              <div className="icon lg:mx-0 mx-4" onClick={navToggle}>
                <div
                  id="nav-icon3"
                  onClick={hemBurger}
                  className={isActive ? "open" : "close"}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {open == true ? (
        <div className="modal-details-fade ContactVoice">
          <div className="modal-details">
            <i
              className="fa-regular fa-circle-xmark text-red-600"
              style={{ float: "right", fontSize: "22px", cursor: "pointer" }}
              onClick={(e) => {
                setOpen(false);
                handlemodal();
              }}
            ></i>
            <div className="">
              <section className="">
                <div className="container m-auto">
                  <div className="w-full">
                    <div className="js_contact_content text-center">
                      <h2 className="text-2xl font-bold text-center ">
                        HAVE AN ENQUIRY
                      </h2>
                      <h1 className=" m-auto lg:py-2 text-base text-gray-500 mx-2	">
                        Ajiva Infotech is available for you 24*7.
                      </h1>
                    </div>
                  </div>

                  <div className="w-full lg:flex  p-2   main-section-tablet">
                    {/* <BrowserRouter> */}
                    <div className=" outer-card-1 lg:w-1/3 h-50 ">
                      <div className="conact-card-1 mx-1 border items-center text-2xl px-2 py-1 lg:py-2 uppercase font-bold flex my-3 cursor-pointer">
                        <div className="py-4 border-r-2 border-indigo-500">
                          <img
                            src={needhelp}
                            alt="help ajivainfotech images"
                            className="pr-5"
                          ></img>
                        </div>
                        <div className=" mx-4 ">
                          <h2 className="text-base text-red-600  font-bold ">
                            NEED HELP
                          </h2>
                          <h4 className="text-base text-gray-700 ">
                            +91 8699981982
                          </h4>
                          <h4 className="text-base text-gray-700 ">
                            0172-4084189
                          </h4>
                        </div>
                      </div>
                      <div className=" conact-card-2 items-center mx-1 border text-2xl px-2 lg:py-2 uppercase font-bold flex my-3 cursor-pointer">
                        <div className="py-4 border-r-2 border-indigo-500">
                          <a href={Pdffile} download="AjivaInfotech.pdf">
                            <img
                              src={pdf}
                              alt="pdf ajivainfotech pdf link download"
                              className=" pr-4"
                            ></img>
                          </a>
                        </div>
                        <div className=" mx-4  ">
                          <a href={Pdffile} download="AjivaInfotech.pdf">
                            <h2 className="text-base text-red-600  font-bold ">
                              DOWNLOAD
                            </h2>
                            <h4 className="text-base text-gray-700 ">
                              COMPANY PROFILE
                            </h4>
                          </a>
                        </div>
                      </div>
                      <div className=" conact-card-3 mx-1 border text-2xl px-2 lg:py-2 uppercase font-bold flex my-3 cursor-pointer">
                        <div className="lg:py-4 border-r-2 border-indigo-500 conact-card-inner">
                          <img
                            src={lets}
                            alt="lets ajvainfotech images"
                            className=" lg:pr-4"
                          ></img>
                        </div>
                        <div className=" mx-4 lg:p-0 pt-1 ">
                          <h2 className="text-base text-red-600  font-bold ">
                            LET'S TALK YOUR
                          </h2>
                          <h4 className="text-base text-gray-700 ">BUSINESS</h4>
                          <div className="ajivainfotechmail">
                            <a
                              href="mailto:info@ajivainfotech.com"
                              style={{
                                color: "red",
                                fontSize: "13px",
                                textTransform: "none",
                                textDecoration: "none", // To remove the underline, if needed
                              }}
                            >
                              info@ajivainfotech.com
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="lg:w-2/3  outer-card-2 lg:px-10  ">
                      <h1 className="text-black underline-offset-0 lg:pt-2 text-gray-500">
                        Fill out this form and tell us little bit about
                        yourself. We will get back to you with best solution.
                      </h1>
                      <form
                        name="contactform"
                        id="contactid"
                        onSubmit={(e) => handleformsubmit(e)}
                        className="space-y-4"
                      >
                        <input
                          type="text"
                          name="fullname"
                          className="w-full lg:w-full rounded-lg border-gray-200 p-2 border my-2"
                          placeholder="Name:"
                          required
                        />
                        <input
                          type="email"
                          name="email"
                          className="w-full lg:w-full rounded-lg border-gray-200 p-2 border my-2"
                          placeholder="Email:"
                          required
                        />
                        <input
                          type="tel"
                          name="phone"
                          className="w-full lg:w-full rounded-lg border-gray-200 p-2 border my-2"
                          placeholder="Phone No:"
                          required
                        />
                        <textarea
                          name="message"
                          className="w-full lg:w-full rounded-lg border-gray-200 p-2 border my-2 h-[80px] resize-none"
                          placeholder="Write your Query here"
                          required
                        ></textarea>
                        <button
                          type="submit"
                          className="inline-block rounded border border-red-600 bg-red-600 py-2 my-1 text-sm font-medium text-white hover:bg-transparent hover:text-red-600 focus:outline-none focus:ring focus:ring-red-500 w-full lg:w-40"
                        >
                          SUBMIT
                        </button>
                      </form>

                      {/* <form
                          action=""
                          name="contactform"
                          id="contactid"
                          className=""
                        >
                          <input
                            type="text"
                            name="fullname"
                            className="lg:w-full rounded-lg border-gray-200 p-2 pe-12 border my-2 "
                            placeholder="Name:"
                            required
                          />
                          <input
                            type="email"
                            name="email"
                            className="lg:w-full rounded-lg border-gray-200 p-2 pe-12 border my-2"
                            placeholder="Email:"
                             required
                          />
                          <input
                            type="tel"
                            name="phone"
                            className="lg:w-full rounded-lg border-gray-200 p-2 pe-12 border my-2"
                            placeholder="Phone No:"
                             required=""
                          />
                          <textarea
                            className="lg:w-full  rounded-lg border-gray-200 p-2 pe-12 border my-2 h-[80px]"
                            id=""
                            name="message"
                            placeholder="Write your Query here"
                             required=""
                          ></textarea>
                          <button id="affter_submit"
                            className="inline-block rounded border border-red-600 bg-red-600 py-2 my-1 text-sm font-medium text-white hover:bg-transparent hover:text-red-600 focus:outline-none focus:ring active:text-black-500 w-40
 "
                            type="button"
                            onClick={(e) => {
                              handleformsubmit(e);
                              // handlemodal()
                            }
                            
                            }
                          >
                            SUBMIT
                          </button>
                        </form> */}
                    </div>
                    {showThanksPopup && (
                      <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
                        <div className="bg-white p-6 rounded shadow-lg text-center relative main-section ">
                          {/* Close Icon */}
                          <div className="absolute close-icon-outer ">
                            <button
                              className="text-red-600 hover:text-red-600 focus:outline-none text-[20px] close-icon-cercle"
                              onClick={() => {
                                setShowThanksPopup(false);
                                setOpen(false);
                              }}
                            >
                              <i className="fas fa-times flex justify-center"></i>{" "}
                              {/* Assuming you're using Font Awesome */}
                            </button>
                          </div>

                          {/* Success Animation */}
                          <div className="success-animation">
                            <svg
                              className="checkmark"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 52 52"
                            >
                              <circle
                                className="checkmark__circle"
                                cx="26"
                                cy="26"
                                r="25"
                                fill="none"
                              />
                              <path
                                className="checkmark__check"
                                fill="none"
                                d="M14.1 27.2l7.1 7.2 16.7-16.8"
                              />
                            </svg>
                          </div>
                          <div className="submiting-text-animation">
                            <span className="text1">Thank you for</span>
                            <span className="text2">submitting!</span>
                          </div>

                          {/* <button
      className="mt-4 bg-red-600 text-white px-4 py-2 rounded hover:bg-transparent hover:text-red-600 focus:outline-none"
      onClick={() => {
        setShowThanksPopup(false);
        setOpen(false);
      }}
    >
      Close
    </button> */}
                        </div>
                      </div>
                    )}
                    {/* </BrowserRouter> */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Header;
