import React, { useState } from "react";
import Logo from "../../img/Logo_2.png";
import { NavLink } from "react-router-dom";
import { headers } from "../../helpers/variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import facbook from "../../footor_icon/FB.png";
import twitter from "../../footor_icon/twitt.png";
import linkedin from "../../footor_icon/in.png";
import instagram from "../../footor_icon/insta.png";
import righticon from "../../img/about-section logos/001.png";
import location from "../../img/about-section logos/locat.png";
import mobilenumber from "../../img/about-section logos/mobile.png";
import emailmassage from "../../img/about-section logos/Mess.png";
import { post_api_request } from "../../helpers/Api.js";
const Footer = () => {
  function submit_form(form_id) {
    let e = document.getElementById(form_id),
      l = new FormData(e),
      t = Array.from(l.entries());
    var n = {};
    for (let o = 0; o < t.length; o++) {
      var s = t[o],
        i = s[0],
        y = s[1];
      y.length > 0 && (void 0 != n[i] ? (n[i] = n[i] + "," + y) : (n[i] = y));
    }
    return n;
  }
  const [email, setEmail] = useState();
  const [showNotification, setshowNotification] = useState();
  const [notdata, setnotdata] = useState();
  const handlechange = (e) => {
    setEmail(e);
  };
  const handlesubmit = () => {
    var payload = {};
    console.log(email);
    payload["email"] = email;
    async function Createcontact(payload) {
      const url = "/newsletter/subscribed";
      const response = await post_api_request(url, payload, headers);
      if (response.status == 201) {
        setshowNotification(true);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else if (response.status == 200) {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    Createcontact(payload);
  };
  return (
    <>
      {/* <BrowserRouter> */}
      <div className="footer-section footer_bg_images lg:py-14">
        <div className="container m-auto lg:py-14 px-4 lg:py-0 lg:px-0">
          <div className="w-full lg:flex p-4 ">
            <div className="lg:w-1/2 mt-5">
              <div>
                <p className="text-4xl font-semibold text-white">
                  Newsletter Signup
                </p>

                <p className="mt-2 mb-2 text-sm text-gray-300">
                  Let us know and set up an appointment where we can walk you
                  through the process
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 m-auto">
              {/* <form className="w-full left[12px]" id="emailsubscribe"> */}
              {/* <label for="UserEmail" className="sr-only">
                  Email
                </label> */}

              <div className=" p-2 mt-6  sm:flex sm:items-center justify-end		">
                <input
                  id=""
                  name="email"
                  onChange={(e) => handlechange(e.target.value)}
                  placeholder="Enter Your Email"
                  className="lg:w-[61%] w-full border-none  sm:text-sm px-3 py-4 rounded-sm"
                />

                <button
                  onClick={(e) => handlesubmit(e)}
                  className="mt-1 w-full bg-red-600 px-2 py-4 text-sm font-bold uppercase tracking-wide text-white transition-none  sm:mt-0 sm:w-auto sm:shrink-0 rounded-sm"
                >
                  Subscribe
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
          <div className="border-b border-[#3b3b3b] py-5"></div>
          <div className="w-full lg:flex lg:py-8 p-4">
            <div className="lg:w-2/5">
              <div>
                <div className="lg:w-40 footer_logo_211">
                  <img
                    width=""
                    height=""
                    className=""
                    src={Logo}
                    alt="footer logo white ajivainfotech"
                  />
                </div>
                <p className="text-white text-sm lg:w-80 w-full footer_logo_211 lg:py-0 leading-relaxed  lg:mt-5">
                  We also provide best in className API Integration including
                  but not limited to Click Up, Slack, Zoho, Twilio, Facebook,
                  Google API, Workato, Trello, Shopify, Zapier, Jaeger
                  Integration & Integromatt.
                </p>
              </div>
            </div>
            <div className="lg:w-2/5">
              <div className="lg:py-0 py-4">
                <p className="font-semibold text-2xl text-white">Contact Us</p>

                <ul className="mt-6 space-y-3 text-sm Integration_including">
                  <li className="flex">
                    <div>
                      <img src={location}></img>
                    </div>
                    {/* <i className="fa-solid fa-location-dot text-red-600 mr-4 text-xl"></i> */}
                    <NavLink
                      to="#"
                      className="text-white text-base transition  hover:opacity-75"
                    >
                      Plot 309, 1st Floor,Sector <br /> 75, S.A.S. Nagar, Punjab
                      160055
                    </NavLink>
                  </li>

                  <li className="flex">
                    <div>
                      {" "}
                      <img src={mobilenumber}></img>
                    </div>
                    {/* <i className="fa-solid fa-mobile-screen-button text-red-600 mr-4 text-xl"></i> */}
                    <NavLink
                      to="#"
                      className="text-white text-base transition hover:opacity-75"
                    >
                      0172-4084189 +918699981982
                    </NavLink>
                  </li>

                  <li className="flex">
                    <div>
                      {" "}
                      <img src={emailmassage}></img>
                    </div>
                    {/* <i className="fa-solid fa-envelope text-red-600 mr-4 text-xl"></i> */}
                    <NavLink
                      to="mailto:info@ajivainfotech.com"
                      className="text-white text-base transition hover:opacity-75"
                    >
                      info@ajivainfotech.com
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:w-1/3">
              <div className="">
                <p className="font-semibold text-2xl text-white">Quick Links</p>

                <ul className="mt-6 API_Integration_includin space-y-3 lg:text-sm">
                  <li className="flex">
                    {/* <a
                        href="/"
                        className="fa-solid fa-chevron-right text-red-600 mr-2 lg:text-base inner-icon"
                      ></a> */}
                    <a href="/">
                      <img src={righticon} />
                    </a>
                    <a
                      href="/"
                      className="text-white lg:text-base transition hover:opacity-75"
                    >
                      Home
                    </a>
                  </li>

                  <li className="flex">
                    {/* <a
                        href="/services"
                        className="fa-solid fa-chevron-right text-red-600 mr-2 lg:text-base inner-icon"
                      ></a> */}
                    <a href="/services">
                      <img src={righticon} />
                    </a>
                    <a
                      href="/services"
                      className="text-white lg:text-base transition hover:opacity-75"
                    >
                      Services
                    </a>
                  </li>

                  <li className="flex">
                    {/* <a
                        href="/blog"
                        className="fa-solid fa-chevron-right text-red-600 mr-2 lg:text-base inner-icon"
                      ></a> */}
                    <a href="/blog">
                      <img src={righticon} />
                    </a>
                    <a
                      href="/blog"
                      className="text-white lg:text-base transition hover:opacity-75"
                    >
                      Blog
                    </a>
                  </li>
                  <li className="flex">
                    {/* <a
                        href="/about"
                        className="fa-solid fa-chevron-right text-red-600 mr-2 lg:text-base inner-icon"
                      ></a> */}
                    <a href="/about">
                      <img src={righticon} />
                    </a>
                    <a
                      href="/about"
                      className="text-white lg:text-base transition hover:opacity-75"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="flex">
                    {/* <a
                        href="/contact"
                        className="fa-solid fa-chevron-right text-red-600 mr-2 lg:text-base inner-icon"
                      ></a> */}
                    <a href="/contact">
                      <img src={righticon} />
                    </a>
                    <a
                      href="/contact"
                      className="text-white lg:text-base transition hover:opacity-75"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:w-1/4 ">
              <div className="lg:py-0 py-4">
                <p className="font-semibold text-2xl text-white">
                  Social Links
                </p>
                <ul className="col-span-2 flex justify-start gap-3 lg:col-span-5 lg:justify-start my-4">
                  <li>
                    <NavLink
                      to="https://www.facebook.com/Ajivainfotech/"
                      rel="noreferrer"
                      target="_blank"
                      aria-label="view our ajivainfotech facebook page"
                      className="text-white transition hover:opacity-75"
                    >
                      <span className="sr-only ">Facebook</span>
                      <img src={facbook}></img>
                      {/* <svg
                          className="h-6 w-6"
                          fill="#fff"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            clipRule="evenodd"
                          />
                        </svg> */}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="https://www.instagram.com/ajivainfotech/"
                      rel="noreferrer"
                      target="_blank"
                      aria-label="view our ajivainfotech instagram page"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      <span className="sr-only">Instagram</span>
                      <img src={instagram}></img>
                      {/* <svg
                          className="h-6 w-6"
                          fill="#fff"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                          />
                        </svg> */}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="https://in.linkedin.com/company/ajivainfotech"
                      rel="noreferrer"
                      target="_blank"
                      aria-label="view our ajivainfotech linkedin page"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      <img src={linkedin}></img>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="https://twitter.com/ajivainfotech"
                      rel="noreferrer"
                      target="_blank"
                      aria-label="view our ajivainfotech twitter handle"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      <img src={twitter}></img>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="lg:mt-8 border-t border-[#3b3b3b] pt-8 pb-3">
            <div className="sm:flex sm:justify-between  sectio-positon-change">
              <p className="text-xs text-gray-200  Allrightsreserved">
                &copy; 2024. Ajiva Infotech. All rights reserved.
              </p>

              <ul className=" flex flex-wrap justify-start lg:gap-4 text-xs sm:mt-0 lg:justify-end">
                <li>
                  <a
                    target="_blank"
                    href="/terms&conditions"
                    // href="https://merchant.razorpay.com/policy/MqO6wFNwZ9CZ4w/terms"
                    className="text-gray-200 transition hover:opacity-75 border-r lg:px-3 pl-0 pr-3"
                  >
                    Terms & Conditions
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="/privacy&policy"
                    // href="https://merchant.razorpay.com/policy/MqO6wFNwZ9CZ4w/privacy"
                    className="text-gray-200 transition hover:opacity-75 border-r px-3"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/refund&policy"
                    // href="https://merchant.razorpay.com/policy/MqO6wFNwZ9CZ4w/refund"
                    className="text-gray-200 transition hover:opacity-75 border-r px-3 "
                  >
                    Refund Policy
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/shipping&policy"
                    // href="https://merchant.razorpay.com/policy/MqO6wFNwZ9CZ4w/shipping"
                    className="text-gray-200 transition hover:opacity-75 Allrightsreserved-inner"
                  >
                    Shipping Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      {/* <Livechat /> */}
    </>
  );
};
export default Footer;
