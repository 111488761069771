import React, { useEffect, useState } from "react";
import Logo from "../../img/about-section logos/ajivalogo_45.png";
import Logo_mob from "../../img/Logo_2.png";
import { headers } from "../../helpers/variables.js";
import Cookies from "js-cookie";
import needhelp from "../../img/Need-help.webp";
import lets from "../../img/Lats-talk.webp";
import pdf from "../../img/Pdf.webp";
import ImportModal from "react-modal";
import Pdffile from "../../img/about-section logos/AjivaInfotech.pdf";
import { NavLink, NavNavLink, useParams } from "react-router-dom";
import style from "../../App.css";
import {
  get_api_request,
  post_api_request,
  put_api_request,
  delete_api_request,
  api_url,
} from "../../helpers/Api.js";

const ContactPopuppage = ({}) => {
  const [ActiveHome, setActiveHome] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [ishemBurger, sethemBurger] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [tools, settools] = useState(false);

  const navToggle = (event) => {
    setIsActive((current) => !current);
  };

  const hemBurger = (event) => {
    sethemBurger((current) => !current);
  };

  const [open, setOpen] = useState(true);
  useEffect(() => {
    const popupvalue = window.localStorage.getItem("Popup");
    if (popupvalue != "Disabled") {
      const delayedAction = () => {
        setOpen(true);
      };
      const timeoutId = setTimeout(delayedAction, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  function submit_form(form_id) {
    let e = document.getElementById(form_id),
      l = new FormData(e),
      t = Array.from(l.entries());
    var n = {};
    for (let o = 0; o < t.length; o++) {
      var s = t[o],
        i = s[0],
        y = s[1];
      y.length > 0 && (void 0 != n[i] ? (n[i] = n[i] + "," + y) : (n[i] = y));
    }
    return n;
  }

  const handleformsubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById("contactid");
    if (form.checkValidity()) {
      var feildsvalue = submit_form("contactid");
      console.log(feildsvalue);
      var payload = {};
      payload["fullname"] = feildsvalue?.fullname;
      payload["email"] = feildsvalue?.email;
      payload["phone"] = feildsvalue?.phone;
      payload["message"] = feildsvalue?.message;
    }
    async function Createcontact(payload) {
      const url = "/api/v1/contactus/";
      const response = await post_api_request(url, payload, headers);
      if (response.status == 201) {
        setShowThanksPopup(true);
      }
      document.getElementById("contactid").reset();
    }
    Createcontact(payload);
  };

  const handlemodal = () => {
    window.localStorage.setItem("Popup", "Disabled");
  };

  return (
    <>
      <div className="">
        <section className="">
          <div className="container m-auto">
            <div className="w-full">
              <div className="js_contact_content text-center">
                <h2 className="text-2xl font-bold text-center ">
                  HAVE AN ENQUIRY
                </h2>
                <h1 className="m-auto lg:pb-2 text-base text-gray-500 mx-2">
                  Ajiva Infotech is available for you 24*7.
                </h1>
              </div>
            </div>

            <div className="w-full lg:flex p-2 main-section-tablet">
              <div className=" outer-card-1 lg:w-1/3 h-50 ">
                <div className="conact-card-1 mx-1 border items-center text-2xl px-2 py-1 lg:py-2 uppercase font-bold flex my-3 cursor-pointer">
                  <div className="py-4 border-r-2 border-indigo-500">
                    <img
                      src={needhelp}
                      alt="help ajivainfotech images"
                      className="pr-5"
                    />
                  </div>
                  <div className="mx-4 ">
                    <h2 className="text-base text-red-600  font-bold ">
                      NEED HELP
                    </h2>
                    <h4 className="text-base text-gray-700 ">+91 8699981982</h4>
                    <h4 className="text-base text-gray-700 ">0172-4084189</h4>
                  </div>
                </div>
                <div className="conact-card-2 items-center mx-1 border text-2xl px-2 lg:py-2 uppercase font-bold flex my-3 cursor-pointer">
                  <div className="py-4 border-r-2 border-indigo-500">
                    <a href={Pdffile} download="AjivaInfotech.pdf">
                      <img
                        src={pdf}
                        alt="pdf ajivainfotech pdf link download"
                        className=" pr-4"
                      />
                    </a>
                  </div>
                  <div className="mx-4 ">
                    <a href={Pdffile} download="AjivaInfotech.pdf">
                      <h2 className="text-base text-red-600  font-bold ">
                        DOWNLOAD
                      </h2>
                      <h4 className="text-base text-gray-700 ">
                        COMPANY PROFILE
                      </h4>
                    </a>
                  </div>
                </div>
                <div className="conact-card-3 mx-1 border text-2xl px-2 lg:py-2 uppercase font-bold flex my-3 cursor-pointer">
                  <div className="lg:py-4 border-r-2 border-indigo-500 conact-card-inner">
                    <img
                      src={lets}
                      alt="lets ajvainfotech images"
                      className=" lg:pr-4"
                    />
                  </div>
                  <div className="mx-4 lg:p-0 pt-1 ">
                    <h2 className="text-base text-red-600  font-bold ">
                      LET'S TALK YOUR
                    </h2>
                    <h4 className="text-base text-gray-700 ">BUSINESS</h4>
                    <div className="ajivainfotechmail">
                      <a
                        href="mailto:info@ajivainfotech.com"
                        style={{
                          color: "red",
                          fontSize: "13px",
                          textTransform: "none",
                          textDecoration: "none", // To remove the underline, if needed
                        }}
                      >
                        info@ajivainfotech.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:w-2/3  outer-card-2 lg:px-10  ">
                <h1 className="text-black underline-offset-0 lg:pt-2 text-gray-500">
                  Fill out this form and tell us a little bit about yourself. We
                  will get back to you with the best solution.
                </h1>
                <form
                  name="contactform"
                  id="contactid"
                  onSubmit={(e) => handleformsubmit(e)}
                  className="space-y-4"
                >
                  <input
                    type="text"
                    name="fullname"
                    className="w-full lg:w-full rounded-lg border-gray-200 p-2 border my-2"
                    placeholder="Name:"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    className="w-full lg:w-full rounded-lg border-gray-200 p-2 border my-2"
                    placeholder="Email:"
                    required
                  />
                  <input
                    type="tel"
                    name="phone"
                    className="w-full lg:w-full rounded-lg border-gray-200 p-2 border my-2"
                    placeholder="Phone:"
                    required
                  />
                  <textarea
                    name="message"
                    className="w-full lg:w-full rounded-lg border-gray-200 p-2 border my-2"
                    placeholder="Message:"
                    required
                  />
                  <div>
                    <button
                      type="submit"
                      className="w-full lg:w-full bg-red-600 text-white py-2 rounded-lg"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactPopuppage;
