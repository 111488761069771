import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "../src/css/responcive.css";
import { getUserRole } from "./helpers/utils.js";
import "./components/Layout/menubaar.css";
import AdminLayout from "./components/Layout/AdminLayout.js";
import CustomerLayout from "./components/Layout/CustomerLayout.js";
import Websitelayout from "./components/Layout/Websitelayout.js";
import SuperAdminLayout from "./components/Layout/SuperAdminLayout.js";
import NotFound from "./components/Website/NotFound.js";
import PermissionDenied from "./components/Website/PermissionDenied.js";
import GoogleCallback from "./components/Website/googleCallback.js";

const App = () => {
  // const userRole = getUserRole();
  const userRole = "super_admin";
  if (window.location.host != "localhost:5000") {
    if (window.location.protocol.toLowerCase() !== "https:") {
      const url = `https://${window.location.host}`;
      window.location.replace(url);
    }
  }
  const ProtectedRoute = ({ element: Component, allowedRoles, ...rest }) => (
    <Route
      {...rest}
      element={
        userRole ? (
          allowedRoles.includes(userRole) ? (
            Component
          ) : (
            <Navigate to="/permission-denied" />
          )
        ) : (
          <Navigate to="/" />
        )
      }
    />
  );
  console.log(userRole, "userRole");

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/google/callback" element={<GoogleCallback />} />
      <Route path="/*" element={<Websitelayout />} />

      {/* Role-Based Layout Routes */}
      <Route
        path="/dashboard/*"
        element={
          userRole === "admin" ? (
            <AdminLayout />
          ) : userRole === "super_admin" ? (
            <SuperAdminLayout />
          ) : userRole === "customer" ? (
            <CustomerLayout />
          ) : (
            <PermissionDenied />
          )
        }
      />
      <Route path="/permission-denied" element={<PermissionDenied />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default App;
