import React, { useState } from "react";
import Aservices from "./Aservices";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
import Ecommerce_image_2 from "../../aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../aservecse_img/Eccomerce_4.png";
import Bgimageecinnerce from "../../img/banner/bgimageecinnerce.png";
import FormSection from "../../components/Website/servicepageheadersection.js";

import Popup from "../popup/dynamicpopup.js";
import Contactpopups from "./contactpopup.js";
function Frontend() {
  const [showPopup, setShowPopup] = useState(false);
  const cards = [
    {
      id: 1,
      image: Ecommerce_image_3, // Replace with actual image import
      title: "Frontend Development",
      description:
        "We specialize in delivering cutting-edge front-end development solutions tailored to meet your unique needs. Our team is proficient in a wide array of technologies and frameworks.",
    },
    {
      id: 2,
      image: Ecommerce_image_2, // Replace with actual image import
      title: "HTML, CSS, and JavaScript Mastery",
      description:
        "We believe that the foundation of any exceptional web experience starts with clean, semantic HTML, elegant CSS styling, and powerful JavaScript functionality.",
    },
    {
      id: 3,
      image: Ecommerce_image_3, // Replace with actual image import
      title: "UI/UX Design Excellence",
      description:
        "We emphasize on creating intuitive and user-friendly interfaces that engage and captivate your audience through excellent UI/UX design principles.",
    },
    {
      id: 4,
      image: Ecommerce_image_4, // Replace with actual image import
      title: "Frameworks and Libraries",
      description:
        "Whether you're looking to leverage React, Angular, Vue.js, or other cutting-edge frameworks, we’ve got you covered with the latest trends and technologies.",
    },
    {
      id: 5,
      image: Ecommerce_image_2, // Replace with actual image import
      title: "Cross-Browser Compatibility and Performance Optimization",
      description:
        "We ensure flawless performance across all major browsers and devices, while prioritizing performance optimization to provide users with a seamless experience.",
    },
    {
      id: 6,
      image: Ecommerce_image_3, // Replace with actual image import
      title: "Accessibility Compliance",
      description:
        "We ensure that your website or application is accessible to all users, following accessibility standards and guidelines to make your digital presence usable for everyone.",
    },
  ];
  const services = [
    "Frontend Development",
    "HTML, CSS, and JavaScript Mastery",
    "UI/UX Design Excellence",
    "Frameworks and Libraries",
    "Cross-Browser Compatibility and Performance Optimization",
    "Accessibility Compliance",
  ];
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Empower Your Digital Presence with Expert Website & App Development Services - Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" HTML, CSS, JavaScript, Responsive Design, UI/UX Design, Frameworks (e.g., React, Angular, Vue.js), Front-end Libraries (e.g., Bootstrap, Foundation), Cross-browser Compatibility, Performance Optimization, Accessibility Compliance"
        />
        <title>
          Empower Your Digital Presence with Expert Website & App Development
          Services - Ajiva Infotech
        </title>
        <meta
          name="description"
          content="Frontend-development- Ajiva Infotech"
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>

      <div className="cocontainer m-auton">
        <section>
          <div className=" container md:m-auto lg:flex justify-between Ecommerce-bg-image items-center">
            <div className=" building-style">
              <h1 style={{ color: "#EE1021" }}>Building</h1>
              <h2>Beautiful Frontend Development that Drive</h2>
              <h2>Results and Inspire Engagement</h2>
              <p>
                From concept to launch, we'll handle your website development
                with precision and expertise
              </p>
              <div className="xl:flex  lg:block  md:flex sm:block  company-details xl:gap-4 gap-4 md:mt-6 lg:mt-5  xl:mt-12">
                <div className="flex items-center lg:gap-2">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.1625 7.77409C28.7107 6.30809 26.9816 5.1457 25.0761 4.35466C23.1705 3.56362 21.1266 3.15975 19.0633 3.16659C10.4183 3.16659 3.37252 10.2124 3.37252 18.8574C3.37252 21.6283 4.10085 24.3199 5.46252 26.6949L3.24585 34.8333L11.5583 32.6483C13.8542 33.8991 16.435 34.5641 19.0633 34.5641C27.7083 34.5641 34.7542 27.5183 34.7542 18.8733C34.7542 14.6774 33.1234 10.7349 30.1625 7.77409ZM19.0633 31.9041C16.72 31.9041 14.4242 31.2708 12.4134 30.0833L11.9383 29.7983L6.99835 31.0966L8.31252 26.2833L7.99585 25.7924C6.69363 23.7136 6.00231 21.3104 6.00085 18.8574C6.00085 11.6691 11.8592 5.81076 19.0475 5.81076C22.5308 5.81076 25.8083 7.17242 28.2625 9.64242C29.4779 10.8519 30.4411 12.2906 31.0961 13.8752C31.7511 15.4598 32.085 17.1586 32.0784 18.8733C32.11 26.0616 26.2517 31.9041 19.0633 31.9041ZM26.22 22.1508C25.8242 21.9608 23.8925 21.0108 23.5442 20.8683C23.18 20.7416 22.9267 20.6783 22.6575 21.0583C22.3883 21.4541 21.6442 22.3408 21.4225 22.5941C21.2008 22.8633 20.9634 22.8949 20.5675 22.6891C20.1717 22.4991 18.905 22.0716 17.4167 20.7416C16.245 19.6966 15.4692 18.4141 15.2317 18.0183C15.01 17.6224 15.2 17.4166 15.4058 17.2108C15.58 17.0366 15.8017 16.7516 15.9917 16.5299C16.1817 16.3083 16.2608 16.1341 16.3875 15.8808C16.5142 15.6116 16.4508 15.3899 16.3558 15.1999C16.2608 15.0099 15.4692 13.0783 15.1525 12.2866C14.8358 11.5266 14.5034 11.6216 14.2659 11.6058H13.5058C13.2367 11.6058 12.825 11.7008 12.4608 12.0966C12.1125 12.4924 11.0992 13.4424 11.0992 15.3741C11.0992 17.3058 12.5084 19.1741 12.6983 19.4274C12.8884 19.6966 15.4692 23.6549 19.3958 25.3491C20.33 25.7608 21.0583 25.9983 21.6283 26.1724C22.5625 26.4733 23.4175 26.4258 24.0984 26.3308C24.8584 26.2199 26.4258 25.3808 26.7425 24.4624C27.075 23.5441 27.075 22.7683 26.9642 22.5941C26.8534 22.4199 26.6158 22.3408 26.22 22.1508Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <h1>+91-86999-81982</h1>
                </div>

                <div className="flex items-center lg:gap-2">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33334 31.6666C5.46251 31.6666 4.71728 31.3568 4.09767 30.7372C3.47806 30.1176 3.16773 29.3718 3.16667 28.4999V9.49992C3.16667 8.62909 3.47701 7.88386 4.09767 7.26425C4.71834 6.64464 5.46356 6.33431 6.33334 6.33325H31.6667C32.5375 6.33325 33.2833 6.64358 33.9039 7.26425C34.5246 7.88492 34.8344 8.63014 34.8333 9.49992V28.4999C34.8333 29.3708 34.5235 30.1165 33.9039 30.7372C33.2843 31.3578 32.5386 31.6676 31.6667 31.6666H6.33334ZM19 20.5833L6.33334 12.6666V28.4999H31.6667V12.6666L19 20.5833ZM19 17.4166L31.6667 9.49992H6.33334L19 17.4166ZM6.33334 12.6666V9.49992V28.4999V12.6666Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <h1> info@ajivainfotech.com</h1>
                </div>

                <div className="flex items-center lg:gap-2">
                  {" "}
                  <span class="phone_content p-">
                    <i class="fa-solid fa-phone-volume text-white text-xl"></i>
                  </span>
                  <h1>0172-4084189</h1>
                </div>
              </div>
            </div>
            <div className="building-form-style">
              <div className="Consultation-style">
                {" "}
                <h1 className="mt-2">Get Consultation now!</h1>
                <h2 className="mt-1">
                  Let’s Discuss to Build Something Great Together!
                </h2>
                <FormSection />
              </div>
            </div>
          </div>
          <section className="bg-white md:py-12 px-4 container m-auto responcivemedia">
            <div className=" flex flex-col md:flex-row  lg:gap-14 md:gap-6 gap-5 ">
              <div className="relative w-full md:w-1/2">
                <img
                  src={Bgimageecinnerce}
                  alt="Team Meeting"
                  className=" w-full h-auto"
                />
              </div>

              {/* Right Column: Text Content */}
              <div className="w-full md:w-1/2 commerce-website">
                <h2 className="font-bold  mb-6 lg:pr-12  md:mr-2 md:pr-2  lg:mr-12">
                  Transform Your Online Business With Our Frontend Development
                  Services
                </h2>
                <p className=" mb-6 leading-relaxed  ">
                  Are you looking to take your online business to the next
                  level? At AIJIVA INFOTECH, our expert team specializes in
                  Frontend design and development, providing custom solutions
                  that cater to your unique business needs. With a focus on
                  affordability and quality, we offer professional Frontend
                  Development Services to help you succeed in the digital
                  marketplace.
                </p>
                <ul className="space-y-3">
                  {services.map((service, index) => (
                    <li key={index} className="flex items-center">
                      <span className="text-red-600 mr-2">➤</span>
                      {service}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
          <section className="container m-auto">
            <div className="ecommerce-heading md:py-12 text-center">
              <h1 className="">Our Frontend Development</h1>
              <h2 className="">Frontend Development Services</h2>
            </div>
          </section>
          <section className="bg-gray-100 py-12 px-4">
            <div className="container m-auto">
              {" "}
              <div className=" mx-auto grid grid-cols-1 lg:grid-cols-3  md:grid-cols-2 gap-8">
                {cards.map((card) => (
                  <div
                    key={card.id}
                    className="bg-white rounded-lg flex flex-col justify-between shadow-lg overflow-hidden text-center p-6"
                  >
                    <div>
                      <img
                        src={card.image}
                        alt={card.title}
                        className="w-full  object-cover mb-4 rounded-md"
                      />
                      <h3 className=" font-bold text-gray-800 mb-4">
                        {card.title}
                      </h3>
                      <p className="text-gray-600 mb-6 text-sm leading-relaxed">
                        {card.description}
                      </p>
                    </div>
                    <div className="details-card">
                      {/* Button to toggle popup */}
                      <button
                        className="explore"
                        onClick={() => setShowPopup(true)} // Open the popup
                      >
                        <span className="icon-right after"></span>
                      </button>

                      {/* Popup */}
                      <Popup
                        show={showPopup}
                        onClose={() => setShowPopup(false)}
                      >
                        <Contactpopups /> {/* Render your popup content here */}
                      </Popup>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </section>

        <Aservices />
      </div>
    </>
  );
}
export default Frontend;
